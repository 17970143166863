<template>
  <b-modal
    id="modal-staff-add"
    ref="modal"
    v-model="open"
    hide-footer
    no-close-on-backdrop
    size="lg"
    title="ADD EXPENCES"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createStaffForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(createExpense)"
        >
          <b-row
            v-for="(expense, expense_index) in expenses_section"
            :key="expense_index"
          >
            <b-col cols="12">
              <b-form-group
                class="required"
                label="Expense Type"
                label-cols-md="4"
                label-for="h-state"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Expense Type"
                  rules="required"
                >
                  <v-select
                    v-model="form.expense_id"
                    :options="expenseOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Expense"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="required"
                label="Amount"
                label-cols-md="4"
                label-for="h-lName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Amount"
                  rules="required"
                >
                  <b-form-input
                    id="amount"
                    v-model="form.amount"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Amount"
                    type="number"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class=""
                label="Remark"
                label-cols-md="4"
                label-for="h-pCode"
              >
                <b-form-input
                  id="remark"
                  v-model="form.remark"
                  placeholder="Remark"
                  type="text"
                  autocomplete="nope"
                  :maxlength="maxChar"
                  :state="form.remark.length < maxChar"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="form.remark.length >= maxChar ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ form.remark.length }}</span> / {{ maxChar }}
                </small>
              </b-form-group>
            </b-col>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="clearForm()"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceRepository = RepositoryFactory.get('finance')

function initialState() {
  return {
    open: false,
    id: null,
    form: {
      expense_id: null,
      remark: '',
      amount: '',
    },
    modalLoading: false,
    loading: false,
    maxChar: 190,
    expenseLoading: false,
    expenseOptions: [],
    expenses_section: [
      {
        type: '',
        amount: '',
        remark: '',
      },
    ],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    isRiderCommissionShowing() {
      return this.form.role_id === 7
    },
  },
  methods: {
    async openModal(depositId) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.depositId = depositId
      this.modalLoading = true
      this.modalLoading = false
      this.readDataforExpenseType()
    },
    async createExpense() {
      this.modalLoading = true
      const data = {
        expense_type_id: this.form.expense_id,
        amount: this.form.amount,
        remark: this.form.remark,
      }
      try {
        await FinanceRepository.createExpense(this.depositId, data)
        this.showSuccessMessage('Expense created successfully')
        this.open = false
        this.$parent.onClickRefresh()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    async readDataforExpenseType() {
      try {
        this.expenseLoading = true
        const { data } = (await FinanceRepository.getExpenseType()).data
        this.expenseOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.expenseLoading = false
    },
    clearForm() {
      this.form.expense_id = null
      this.form.amount = ''
      this.form.remark = ''
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/required.scss';
</style>
