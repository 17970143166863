<template>
  <div>
    <div>
      <b-card no-body>
        <div>
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <div class="m-2">
              <!-- input -->
              <b-row>
                <b-col
                  md="8"
                  class="d-flex align-items-center justify-content-start mb-1 mt-1 mb-md-0"
                >
                  <b-button
                    v-if="finance_key === 'f_key_1'"
                    v-can="'branch-finance-show'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="mobile-margin"
                    @click="onClickExpense(id)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                    Add New
                  </b-button>
                </b-col>
                <b-col
                  class="mt-2 d-flex justify-content-end"
                >
                  <ReportTableButtons
                    :items="rows"
                    :json_fieldz="json_fields"
                    :name="`${downloadReportName} - Page ${page}.xls`"
                    :bulkname="`${downloadReportName}.xls`"
                    :fetch="fetchExpenseListNoPagination"
                    @refresh="fetchExpenseList"
                  />
                </b-col>
              </b-row>
            </div>
            <!-- table -->
            <b-table
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              hover
              responsive
              striped
              show-empty
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(id)="data">
                <div class="d-flex align-items-start">
                  <div>
                    <div class="font-weight-bolder">
                      <b-form-checkbox
                        v-model="selectedExpenses"
                        :value="data.item.id"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(remark)="data">
                <div>
                  <b-badge
                    v-if="data.value.length > 0"
                    :variant="'light-success'"
                    @click="onClickViewRemark(data.value, data.item.expense_type.name)"
                  >
                    {{ `View remark` }}
                  </b-badge>
                  <b-badge
                    v-if="!(data.value.length > 0)"
                    :variant="'light-danger'"
                  >
                    {{ `No Remark` }}
                  </b-badge>
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  cols="12"
                  md="3"
                >
                  <label class="width-75">Per page</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-1 width-100 mr-2"
                  />
                </b-col>
                <!-- Pagination -->
                <b-col
                  class="d-flex align-items-center justify-content-sm-end"
                  cols="12"
                  md="9"
                >
                  <span class="text-muted pagination-text mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }}entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total) ? meta.total: 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
    </div>
    <expense-form ref="set_expense_modal" />
    <view-remark ref="view_remark" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BBadge,
  // BAvatar,
  // BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import ExpenseForm from '@/components/FinanceModuleForms/DepositExpenseForm/SetExpenseModal.vue'
import ViewRemark from '@/components/AllOrders/Remark/ViewRemark.vue'
import store from '@/store/index'

const FinanceRepository = RepositoryFactory.get('finance')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BPagination,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    vSelect,
    BBadge,
    // BAvatar,
    // BFormCheckbox,
    ExpenseForm,
    ViewRemark,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      id: null,
      avatarText,
      filters: {},
      total: 0,
      meta: {},
      dataLoading: false,
      // Start BS Table
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      // End BS Table
      pageLength: 20,
      dir: false,
      selectedCities: [],
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'created_at',
          label: 'Added Date',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'expense_type.name',
          label: 'Expense Type',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'remark',
          label: 'Remark',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
      ],
      rows: [],
      json_fields: {
        'Added Date': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Expense Type': {
          field: 'expense_type.name',
          callback: value => `"${value}"`,
        },
        Amount: 'amount',
        Remark: {
          field: 'remark',
          callback: value => `"${value}"`,
        },
      },
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchExpenseList()
      },
    },
    deep: true,
    page() {
      this.fetchExpenseList()
    },
    perPage() {
      this.fetchExpenseList()
    },
  },
  created() {
    this.id = this.$route.params.id
    this.finance_key = this.$route.params.finance_key
  },
  mounted() {
    this.getWarehouseList()
  },
  methods: {
    async fetchExpenseList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await FinanceRepository.getExpenseList(this.id, this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchExpenseListNoPagination() {
      try {
        const { data } = (await FinanceRepository.getExpenseAllData(this.id, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    onClickCreateCity() {
      this.$refs.set_city.openModal(null, 'add')
    },
    onClickEditCity(id) {
      this.$refs.set_city.openModal(id, 'edit')
    },
    onClickSelectAll() {
      this.selectedCities = this.rows.map(n => n.id)
    },
    onClickDeselctAll() {
      this.selectedCities = []
    },
    changePage(value) {
      this.perPage = value
    },
    handleChangePage(page) {
      this.page = page
      this.fetchExpenseList()
    },
    filterQueryUpdate() {
      this.fetchExpenseList()
    },
    onClickRefresh() {
      this.fetchExpenseList()
    },
    onClickExpense(id) {
      this.$refs.set_expense_modal.openModal(id)
    },
    onClickViewRemark(remark, remarkType) {
      this.$refs.view_remark.openModal(remark, remarkType)
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/required.scss';
@media (max-width: 767px) {
  .table-responsive {
    position: sticky;
    top: 0;
  }
  .mobile-margin {
    margin-bottom: 20px;
    width: 100%;
  }
  .pagination-text {
    font-size: 0.75rem;
  }
}
</style>
