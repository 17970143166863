<template>
  <b-modal
    id="modal-flag-detail"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="Expense Remark"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form-textarea
        v-model="remark"
        rows="3"
        :disabled="true"
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  VBModal,
  BFormTextarea,
  // BListGroup,
  // BListGroupItem,
  // BBadge,
  // BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
// import { RepositoryFactory } from '@/repository/RepositoryFactory'
// import vSelect from 'vue-select'

// const orderRepository = RepositoryFactory.get('order')

function initialState() {
  return {
    remarkType: 'Deposit Expense Remark',
    remark: 'No Remark',
    open: false,
    id: null,
    modalLoading: false,
    loading: false,
    form: {},
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BFormTextarea,
    // BListGroup,
    // BListGroupItem,
    // BBadge,
    // BAlert,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(remark, remarkType) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.remark = remark
      this.remarkType = remarkType
      this.modalLoading = true
      // await this.fetchRemarkDetails()
      this.modalLoading = false
    },
    // async fetchRemarkDetails() {
    //   try {
    //     const { data } = (await orderRepository.getOrderFlagDetailsById(this.id)).data
    //     this.form = data
    //   } catch (e) {
    //     this.convertAndNotifyError(e)
    //   }
    // },
  },
}
</script>
<style scoped>
</style>
